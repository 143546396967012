<template>
  <TransitionRoot appear :show="open" as="template">
    <Dialog
      as="div"
      :initial-focus="focusRef"
      class="relative z-40"
      @close="autoClose"
    >
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <DialogOverlay
          class="fixed inset-0 bg-black bg-opacity-10 backdrop-blur-sm"
        />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              :class="[
                'relative w-full transform rounded-2xl text-left align-middle transition-all',
                size === 'full'
                  ? 'container h-[calc(100vh-2rem)] w-screen px-2 sm:px-4'
                  : 'mx-2 max-w-md bg-white p-4 shadow-xl'
              ]"
            >
              <input ref="focusRef" type="hidden" />
              <DialogTitle as="h3">
                <button
                  v-if="showX"
                  :class="[
                    'Btn--icon !absolute z-10',
                    size === 'full' ? 'right-2 top-0' : 'right-4 top-4'
                  ]"
                  type="button"
                  aria-label="Close"
                  @click="emit('close')"
                >
                  <Icon name="x" class="h-4 w-4 text-gray-600" />
                </button>
                <slot name="title">{{ title }}</slot>
              </DialogTitle>
              <div class="flex h-full w-full justify-center text-center">
                <slot>
                  <p v-if="description" class="text-sm text-gray-500">
                    {{ description }}
                  </p>
                </slot>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogOverlay,
  DialogTitle
} from '@headlessui/vue'

// defines
const props = defineProps<{
  open: boolean
  title?: string
  description?: string
  staticBackDrop?: boolean
  buttons?: {
    text: string
    handler: () => void
    loading?: boolean
    alt?: boolean
  }[]
  size?: 'small' | 'full'
  showX?: boolean
}>()
const emit = defineEmits(['open', 'close', 'confirm'])

// data
const focusRef = ref<HTMLElement | null>(null)

const themeOptions = computed(() => props.open)

useModalThemeColor(themeOptions)

// methods
const autoClose = () => {
  if (props.staticBackDrop === true) return
  emit('close')
}
</script>
